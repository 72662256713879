import React from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

const useStyles = makeStyles(theme => ({
  alertHeaderContainer: {
    color: theme.palette.warning.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      fontSize: '2rem',
      marginRight: theme.spacing(1),
    },
  },
}));

export const Alert: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className={classes.alertHeaderContainer}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <Typography variant="h4" component="p">
            Uwaga
          </Typography>
        </div>
        {children}
      </CardContent>
    </Card>
  );
};
