import React from 'react';
import { Section } from '@components/section/section';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  divider: {
    borderTop: '2px dashed #CCC',
  },
}));

export const Divider: React.FC = () => {
  const classes = useStyles();

  return (
    <Section contained>
      <hr className={classes.divider} />
    </Section>
  );
};
