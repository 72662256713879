import React from 'react';
import { graphql } from 'gatsby';
import { FullScreenLayout } from '@components/layouts';
import { Section } from '@components/section/section';
import { BreadcrumbContainer } from '@components/breadcrumb-container/breadcrumb-container';
import { AuthorSignature } from '@components/author-signature/author-signature';
import { Divider } from '@components/divider/divider';
import { CommentListing } from '@components/comment-listing/comment-listing';
import { FloatingActionButton } from '@components/floating-action-button/floating-action-button';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Container,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { faMicroscope } from '@fortawesome/free-solid-svg-icons/faMicroscope';
import { Alert } from '@components/alert/alert';
import { fullDateFormat } from '../../utils/date-utils';
import { useLikes } from '../../hooks/useLikes';
import { useSetHeaderContext } from '../../hooks/useSetHeaderContext';

interface ExperimentProps {
  data: {
    mdx: {
      body: string;
      slug: string;
      frontmatter: {
        author: string;
        title: string;
        subtitle: string;
        publishedAt: string;
        image: string;
        keywords: string;
        github: string;
        experiment: string;
      };
    };
    imageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const useStyles = makeStyles(theme => ({
  alertContainer: {
    fontFamily: 'Montserrat, sans-serif',
  },
  experimentBlock: {
    margin: `${theme.spacing(2)}px auto`,
  },
  justifyBody: {
    textAlign: 'justify',
  },
  iframe: {
    width: '100%',
    minHeight: '700px',
    border: 'none',
  },
}));

export default ({ data }: ExperimentProps): React.ReactElement => {
  const { frontmatter } = data.mdx;

  const { thumbsUp, likes } = useLikes(data.mdx.slug);

  useSetHeaderContext({
    image: data.imageSharp.gatsbyImageData,
    title: frontmatter.title,
    subtitle: frontmatter.subtitle,
    height: 300,
  });

  const classes = useStyles();

  return (
    <FullScreenLayout>
      <Container>
        <BreadcrumbContainer
          links={[
            { link: '/experiments', text: 'Eksperymenty', icon: faMicroscope },
            {
              link: `/${data.mdx.slug}`,
              text: frontmatter.title,
              icon: faFlask,
            },
          ]}
        />
        <Section contained>
          <FloatingActionButton
            icon={faThumbsUp}
            likes={likes}
            onClick={thumbsUp}
          />
          <Typography variant="subtitle2" color="textSecondary">
            opublikowano {fullDateFormat(frontmatter.publishedAt)} r.
          </Typography>
          <div className={classes.justifyBody}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </div>
        </Section>
      </Container>
      <Hidden mdDown>
        <iframe
          src={frontmatter.experiment}
          title={frontmatter.title}
          className={classes.iframe}
        />
      </Hidden>
      <Hidden lgUp>
        <Container className={classes.experimentBlock}>
          <Alert>
            <Typography variant="body2">
              Ten projekt możesz zobaczyć na większym ekranie, lub zobaczyć
              oryginał&nbsp;
              <Link
                href={frontmatter.experiment}
                target="_blank"
                rel="noreferrer"
              >
                w nowym oknie.
              </Link>
            </Typography>
          </Alert>
        </Container>
      </Hidden>
      <AuthorSignature username={frontmatter.author} />
      <Divider />
      <CommentListing slug={data.mdx.slug} />
    </FullScreenLayout>
  );
};

export const query = graphql`
  query SingleExperimentQuery($slug: String!, $image: String!) {
    mdx(slug: { eq: $slug }) {
      slug
      body
      frontmatter {
        author
        title
        subtitle
        publishedAt
        image
        keywords
        github
        experiment
      }
    }
    imageSharp(resize: { originalName: { eq: $image } }) {
      gatsbyImageData
    }
  }
`;
