import compareDesc from 'date-fns/compareDesc';
import { useState } from 'react';
import { CommentDao, CommentEntity } from '@models/CommentEntity';
import {
  getFirestore,
  collection,
  where,
  query,
  onSnapshot,
} from 'firebase/firestore';
import { firebaseApp } from '../utils/init-firebase';

interface UseCommentsHook {
  loadComments: () => void;
  comments: CommentEntity[];
  isLoading: boolean;
}

const stub: UseCommentsHook = {
  loadComments: () => undefined,
  comments: [],
  isLoading: false,
};

export const useComments = (slug: string): UseCommentsHook => {
  const [comments, setComments] = useState<CommentEntity[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  if (!firebaseApp) {
    return stub;
  }

  const firestore = getFirestore(firebaseApp);
  const collectionRef = collection(firestore, 'comments');
  const bySlugQuery = query(collectionRef, where('slug', '==', slug));

  const loadComments = () => {
    setIsLoading(true);
    onSnapshot(bySlugQuery, snapshot => {
      const sorted = snapshot.docs
        .map(doc => {
          const data = doc.data() as CommentDao;
          return {
            id: doc.id,
            date: data.time.toDate(),
            slug: data.slug,
            content: data.content,
            name: data.name,
          } as CommentEntity;
        })
        .sort((a, b) => compareDesc(a.date, b.date));

      setComments(sorted);
      setIsLoading(false);
    });
  };

  return { loadComments, comments, isLoading };
};
