import {
  getFirestore,
  collection,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import { useState } from 'react';
import { firebaseApp } from '../utils/init-firebase';

interface UseFirestoreDocHook {
  save: <T>(collectionName: string, document: T) => Promise<boolean>;
  submitted: boolean;
  successful: boolean;
  createTimestamp: () => Timestamp;
}

const stub: UseFirestoreDocHook = {
  save: () => Promise.resolve(false),
  submitted: false,
  successful: false,
  createTimestamp: () => Timestamp.now(),
};

export const useFirestoreDoc = (): UseFirestoreDocHook => {
  if (!firebaseApp) {
    return stub;
  }

  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const firestore = getFirestore(firebaseApp);

  const save = <T>(collectionName: string, document: T): Promise<boolean> =>
    new Promise((resolve, reject) => {
      const collectionRef = collection(firestore, collectionName);
      addDoc(collectionRef, document)
        .then(() => {
          setSuccessful(true);
          setSubmitted(true);
          resolve(true);
        })
        .catch(() => {
          setSuccessful(false);
          setSubmitted(true);
          reject();
        });
    });

  const createTimestamp = () => Timestamp.now();

  return { save, submitted, successful, createTimestamp };
};
