import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Fab, makeStyles, Typography } from '@material-ui/core';

interface FloatingActionButtonProps {
  icon: IconDefinition;
  likes: number;
  onClick: () => void;
}

const useStyles = makeStyles(theme => ({
  fab: {
    top: 250,
    position: 'absolute',
    color: '#FFF',
    zIndex: 2,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({
  icon,
  likes,
  onClick,
}) => {
  const [clicked, setClicked] = useState(false);

  const classes = useStyles();

  const handleClick = () => {
    if (clicked) return;
    setClicked(true);
    onClick();
  };

  return (
    <Fab
      variant="circular"
      color="secondary"
      size="large"
      className={classes.fab}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={icon} className={classes.icon} />
      <Typography variant="body2" component="span">
        {likes}
      </Typography>
    </Fab>
  );
};
