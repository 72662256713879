import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Breadcrumbs, Link, makeStyles } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { ThemeAugument } from '../../styles/theme-augument';

type LinkItem = {
  text: string;
  link: string;
  icon?: IconDefinition;
};

interface BreadcrumbContainerProps {
  links: LinkItem[];
}

const useStyles = makeStyles((theme: ThemeAugument) => ({
  root: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  active: {
    color: theme.palette.primary.main,
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export const BreadcrumbContainer: React.FC<BreadcrumbContainerProps> = ({
  links,
}) => {
  const classes = useStyles();

  const generateLinks = (items: LinkItem[]) =>
    items.map((el, idx) => (
      <Link
        component={GatsbyLink}
        to={el.link}
        key={`breadcrumb-${idx}`}
        className={classes.link}
        activeClassName={classes.active}
        color="inherit"
      >
        {el.icon ? (
          <FontAwesomeIcon icon={el.icon} className={classes.icon} />
        ) : (
          <FontAwesomeIcon icon={faDotCircle} className={classes.icon} />
        )}
        {el.text}
      </Link>
    ));

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          component={GatsbyLink}
          className={classes.link}
          color="inherit"
        >
          <FontAwesomeIcon icon={faHome} className={classes.icon} />
          Strona główna
        </Link>
        {links && generateLinks(links)}
      </Breadcrumbs>
    </div>
  );
};
