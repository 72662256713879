import React from 'react';
import { CommentDao } from '@models/CommentEntity';
import { useFormik } from 'formik';
import {
  Avatar,
  Button,
  createStyles,
  Divider,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useFirestoreDoc } from '../../../hooks/useFirestoreDoc';

interface CommentBoxProps {
  slug: string;
  onCommentSubmitted: () => void;
}

const validate = (values: CommentDao) => {
  const errors = {} as { content?: string; name?: string };
  if (!values.content) errors.content = 'Pole wymagane';
  if (!values.name) errors.name = 'Pole wymagane';
  return errors;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    control: {
      marginTop: theme.spacing(1),
    },
    block: {
      display: 'block',
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginRight: theme.spacing(2),
    },
  })
);

export const CommentBox: React.FC<CommentBoxProps> = ({
  slug,
  onCommentSubmitted,
}) => {
  const { save, createTimestamp } = useFirestoreDoc();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      content: '',
      name: '',
      slug,
      time: createTimestamp(),
    } as CommentDao,
    validate,
    onSubmit: (comment: CommentDao) => {
      formik.resetForm();
      save('comments', comment).then(() => onCommentSubmitted);
    },
  });

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            className={classes.large}
            alt={`${formik.values.name}-decoration-image`}
            src={`https://avatars.dicebear.com/api/bottts/${formik.values.name}.svg`}
          />
        </ListItemAvatar>
        <form
          autoComplete="false"
          className={classes.root}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            className={classes.control}
            name="name"
            type="text"
            label="Autor"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            size="small"
          />
          <TextField
            className={classes.control}
            type="text"
            placeholder="Wpisz swój komentarz"
            name="content"
            variant="outlined"
            error={formik.touched.content && Boolean(formik.errors.content)}
            helperText={formik.touched.content && formik.errors.content}
            value={formik.values.content}
            onChange={formik.handleChange}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button color="primary" endIcon={<SendIcon />} type="submit">
                    Prześlij
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
