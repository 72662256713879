import React from 'react';
import { CommentEntity } from '@models/CommentEntity';
import {
  Avatar,
  createStyles,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { fullDateWithTimeFormat } from '../../../utils/date-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    block: {
      display: 'block',
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginRight: theme.spacing(2),
    },
  })
);

export const Comment: React.FC<CommentEntity> = ({
  id,
  content,
  name,
  date,
}) => {
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.root}>
        <ListItemAvatar>
          <Avatar
            className={classes.large}
            alt={`${name}-decoration-image`}
            src={`https://avatars.dicebear.com/api/bottts/${id}.svg`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            <>
              <Typography
                variant="body2"
                component="span"
                className={classes.block}
              >
                {fullDateWithTimeFormat(date)}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                className={classes.block}
              >
                {content}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
