import React, { useEffect } from 'react';
import { CommentBox } from '@components/comment-listing/comment-box/comment-box';
import {
  CircularProgress,
  Container,
  createStyles,
  List,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Comment } from './comment/comment';
import { useComments } from '../../hooks/useComments';

interface CommentListingProps {
  slug: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: 200,
      justifyContent: 'center',
      alignItems: 'center',
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  })
);

export const CommentListing: React.FC<CommentListingProps> = ({ slug }) => {
  const { comments, loadComments, isLoading } = useComments(slug);
  const classes = useStyles();

  useEffect(() => {
    loadComments();
  }, []);

  const render = () => (
    <>
      <CommentBox slug={slug} onCommentSubmitted={loadComments} />
      {comments.map(comment => (
        <Comment {...comment} key={comment.id} />
      ))}
    </>
  );

  return (
    <Container maxWidth="lg" className={classes.root}>
      {isLoading ? (
        <CircularProgress size={100} />
      ) : (
        <List className={classes.list}>{render()}</List>
      )}
    </Container>
  );
};
