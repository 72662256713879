import { useState } from 'react';
import { ExperimentEntity } from '@models/ExperimentEntity';
import {
  getFirestore,
  runTransaction,
  onSnapshot,
  doc,
  DocumentReference,
} from 'firebase/firestore';
import { firebaseApp } from '../utils/init-firebase';

export interface UseLikesHook {
  likes: number;
  thumbsUp: () => void;
}

const stub: UseLikesHook = {
  likes: 0,
  thumbsUp: () => undefined,
};

export const useLikes = (slug: string): UseLikesHook => {
  if (!firebaseApp) {
    return stub;
  }

  const [likes, setLikes] = useState(0);
  const firestore = getFirestore(firebaseApp);
  const error = new Error(`Document ${slug} does not exist!`);

  const documentRef: DocumentReference<ExperimentEntity> = doc(
    firestore,
    slug
  ) as DocumentReference<ExperimentEntity>;

  const fetchLikes = () => {
    onSnapshot(documentRef, result => {
      if (!result.exists()) {
        throw error;
      }
      const data = result.data();
      setLikes(data.likes);
    });
  };

  const thumbsUp = () => {
    runTransaction(firestore, async transaction => {
      const document = await transaction.get<ExperimentEntity>(documentRef);
      if (!document.exists()) {
        throw error;
      }
      const newLikes = document.data().likes + 1;
      transaction.update(documentRef, {
        likes: newLikes,
      });
    });
  };

  fetchLikes();

  return { likes, thumbsUp };
};
