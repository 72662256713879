import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { Section } from '@components/section/section';
import { makeStyles } from '@material-ui/core';

interface AuthorSignatureProps {
  username: string;
  userProfile?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'right',
  },
}));

export const AuthorSignature: React.FC<AuthorSignatureProps> = ({
  username,
}) => {
  const classes = useStyles();

  return (
    <Section contained>
      <p className={classes.root}>
        {' '}
        ― {username} <FontAwesomeIcon icon={faUserCircle} />
      </p>
    </Section>
  );
};
